<template>
  <div>
    <div id="home-bg-titlebar"></div>
    <div id="home-bg" :class="{ moody: moody}"></div>
  </div>
</template>

<script>
export default {
  name: "HomeBackground",
  props: {
    moody: Boolean
  }
};
</script>

<style scoped lang="scss">
#home-bg-titlebar {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  z-index: -1;
  backdrop-filter: blur(1px);
  background-image: linear-gradient(
    180deg,
    rgba(white, 0.15) 0%,
    rgba(black, 0) 100%
  );

  @media (min-width: 576px) {
    height: 100px;
  }
}

#home-bg {
  width: 100%;
  height: 350px;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -2;

  @media (min-width: 576px) {
    height: 500px;
  }

  &.moody {
    opacity: 0.6;
  }
}
</style>